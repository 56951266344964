$(document).ready(function () {

	// responsive images
	if ('objectFit' in document.documentElement.style === false) {
		$("[data-object-fit]").each((i, item)=>{
			let $t = $(item);
			let imgUrl = $t.attr('src');
			let fitStyle = $t.attr('data-object-fit');
			if (imgUrl) {
				$t.parent().css({
					'backgroundImage': 'url(' + imgUrl + ')',
					'backgroundSize': fitStyle
				}).addClass('fit-img');
			}
		})
	}

	// mask for input tel
	$(".js-tel").mask("+7 (999) 999-99-99", { placeholder: "+7 (___) ___-__-__" });

	$(".b-date input").flatpickr({
		locale: 'ru',
		dateFormat: "d.m.Y",
	});
	flatpickr ? flatpickr.l10ns.ru.rangeSeparator= " - " : $.noop();
	$(".b-date--range input").flatpickr({
		locale: 'ru',
		dateFormat: "d.m.Y",
		mode: "range"
	});

	// if ($(window).width() < 960) {}
	$(document).on('click','.dropdown__title', function () {
		$(this)
		.toggleClass('active')
		.next().slideToggle(0)
	});

	// sliders initialization
	$('.js-slider').owlCarousel({
		items:1,
		loop:true,
		margin:10,
		autoplay: 7000,
		nav:true
	});
	$('.cards-slider__carousel').owlCarousel({
		items:4,
		margin:0,
		autoplay: 7000,
		nav:true,
		dots: false,
		responsive : {
			0 : {
				items:1
			},
			768 : {
				items:3
			},
			1200 : {
				items:4
			}
		}
	});
	$('.shop__slider').owlCarousel({
		margin:0,
		nav:true,
		dots: false,
		responsive : {
			0 : {
				items:1
			},
			768 : {
				items:3
			},
			1200 : {
				items:4
			}
		}
	});

	// js gallery layout
	$('.js-grid').masonry({
		itemSelector: '.js-grid-item',
		percentPosition: true,
		columnWidth: '.js-grid-sizer'
	});

	// modals
	$.arcticmodal('setDefault', {
		overlay: {
			css: {
				backgroundColor: 'rgb(38, 43, 47)',
				opacity: 0.9
			}
		},
		openEffect: {
			speed: 200
		},
		closeEffect: {
			speed: 200
		},
	});
	$(document).on('click', '.ajax-popup', function (e) {
		e.preventDefault();
		$.arcticmodal('close');
		let url = $(this).attr('href');

		$.arcticmodal({
			type: 'ajax',
			url: url,
			afterLoadingOnShow: function(data, el) {
				fileUploader(document.querySelectorAll('.file-upload__input'));
			}
		});
	});
	$(document).on('submit', '.arcticmodal-container form', function (e) {
		e.preventDefault();
		$.arcticmodal('close');
		let $t = $(this);
		let url = $t.attr('action');
		let successText = $t.attr('data-success-text');

		$.arcticmodal({
			type: 'ajax',
			url: url,
			ajax: {
				type: 'GET',
				cache: false,
				success: function(data, el, responce) {
					let $content = $(responce);
					$('.popup__title', $content).text(successText);
					data.body.html($content);
				}
			}
		});
	});
	$(document).on('click', '.js-gallery a', function (e) {

		e.preventDefault();
		if ($(window).width() >= 768) {


			let index = $(this).parent().index();

			let scrollFix = (el)=> {
				let scrollSize = getScrollbarSize();
				$('.modal-gallery__thumbs-scroll', $(el)).css('marginRight', - scrollSize)
			}

			let setScrollPos = (el) => {
				let $gallery = $(el);
				let $scroll = $('.modal-gallery__thumbs-scroll', $gallery);
				let scrollPos = $('[data-mg-list]', $gallery).offset().top;
				let currentPos = $('[data-mg-list] .active', $gallery).offset().top;
				$scroll.stop( true, true ).animate({
					scrollTop: currentPos - scrollPos
				}, 300);
			}

			let galleryActions = (el)=> {
				let $gallery = $(el);
				let $scroll = $('.modal-gallery__thumbs-scroll', $gallery);


				$('.modal-gallery__thumb', $gallery)[index].classList.add('active');


				$(document).on('click', '.modal-gallery__thumb a', function(e){
					e.preventDefault();
					let $t = $(this);
					let $arcticmodal = $('.arcticmodal-container');
					let $bigImg = $('[data-mg-img]', $arcticmodal);


					let scrollPos = $('[data-mg-list]', $gallery).offset().top;
					$scroll.stop( true, true ).animate({
						scrollTop: ($t.parent().offset().top) - scrollPos
					}, 300);

					$t.parent().addClass('active').siblings().removeClass('active');
					$bigImg.attr('src', $t.attr('href'))
					.parent().addClass('loading');

					$bigImg.load(function () {
						$bigImg.parent().removeClass('loading')
					})

					$('[data-mg-caption]', $arcticmodal).text($t.attr('title'));
				});
				$(document).on('click', '[data-mg-arrow]', function(e){
					let moveTo = $(this).attr('data-mg-arrow');
					let $activeThumb = $('.modal-gallery__thumb.active', $gallery);
					if (moveTo === 'next') {
						$activeThumb.next().find('a').click();
					} else if(moveTo === 'prev') {
						$activeThumb.prev().find('a').click();
					}
				});
			};

			let makeGallery = (galleryHtml)=>{
				let c = $(galleryHtml);
				let images = [];
				let thumbs = '';

				$('.js-gallery a').each(function (i, val) {
					let $t = $(this);
					images.push({
						'thumb': $t.attr('href'),
						'origin': $('img', $t).attr('src'),
						'title': $t.attr('title')
					});
				});

				$.each(images,  (i) => {
					thumbs+='<li class="modal-gallery__thumb">'
					+'<a href='+images[i].thumb+' title="'+images[i].title+'">'
					+'<img src='+images[i].origin+' alt="" data-object-fit="cover"/>'
					+'</a></li>';
				});

				$('[data-mg-list]', c).html(thumbs);
				$('[data-mg-img]', c).attr('src', $(this).attr('href'));
				$('[data-mg-caption]', c).text($(this).attr('title'));

				scrollFix(c);
				galleryActions(c);

				return c;
			};

			$.arcticmodal({
				type: 'ajax',
				url: 'ajax/popup-gallery.html',
				ajax: {
					success: function(data, el, responce) {
						data.body.html( makeGallery(responce) );
					}
				},
				afterLoadingOnShow: function(data) {
					setScrollPos(data.body[0].firstChild)
				}
			});
		} else {
			window.open($(this).attr('href'), '_blank');
		}

	});

	$(document).on('click', '.top-toggle', function () {
		const $t = $(this);
		$t.toggleClass('active');
		$( $t.attr('data-toggle') ).slideToggle(200);
	});
	$(document).on('click', '.search__trigger', function () {
		const $parent = $(this).parent();
		const ww = $(window).width();
		$parent.toggleClass('search--active');
		if ( ww < 768 ) {
			$('.search__form', $parent).css('width', ww - 90)
		}
		setTimeout(function() {
			$('input', $parent).focus();
		}, 50);
	});
	$(document).on('click', '.header__menu-trigger', function () {
		$('.header').toggleClass('nav-showed');
	});

	// actions on page with polls
	$(document).on('click', '.poll__trigger', function (e) {
		e.preventDefault();
		let $t = $(this);
		let $parent = $t.closest('.poll');

		$t.closest('.poll').toggleClass('poll--active')
		.find('.poll__body').slideToggle(200);
	});
	$(document).on('submit', '.poll__options form', function (e) {
		let $form = $(this);
		let $container = $form.closest('.poll').find('.poll__body');
		e.preventDefault();
		ajaxFormSubmit($form, $container);
	});

	// ajax filter on page with shops
	$(document).on('click', '.b-categories__more', function () {
		$(this).parent().toggleClass('b-categories--show-all')
	});
	$(document).on('submit', '.js-shops-form', function (e) {
		let $container = $('.shops__list');
		e.preventDefault();
		ajaxFormSubmit( $(this), $container );
	});
	shopsFormListener();
	$(document).on('click', '.shops__header-link', function (e) {
		let $t = $(this);
		let url = $t.attr('href');
		let $content = $('.shops__content');

		e.preventDefault();
		if ( !$t.hasClass('active') ) {
			$t.addClass('active').siblings().removeClass('active');

			$content.addClass('ajax-loader--active');

			let jqXHR = $.ajax({
				url: url,
				type: "GET"
			});
			jqXHR.done(function (data) {
				setTimeout(()=>{
					let $data = $(data).find('.shops__content').html();
					$content.removeClass('ajax-loader--active')
					.html( $data );
					shopsFormListener();
					history.pushState(null, null, url);
				}, 200);
			});
			jqXHR.fail(function () {
				console.log('error on submiting ajax form');
			});
		}
	});

	// fileupload logos on cabinet page
	fileUploader(document.querySelectorAll('.file-upload__input'));


	// close on focus lost
	$(document).click(function(e) {
		var $trg = $(e.target);
		if (!$trg.closest(".dropdown").length) {
			$('.dropdown__title')
			.removeClass('active')
			.next().slideUp(0);
		}
		if (!$trg.closest(".header__search").length) {
			$('.header__search').removeClass('search--active')
			.find('.search__form').removeAttr('style');
		}
		if (!$trg.closest(".mobile-nav").length && !$trg.hasClass('header__menu-trigger') && !$trg.hasClass('header__trigger-icon') ) {
			$('.header').removeClass('nav-showed');
		}
	});

	// add map script to page
	if (!$('#mapit').length == 0) {
		let key = 'AIzaSyC3lkwAB0BVbMpEsubfUytOgxEGfqcWtyE';
		let fName = 'mapInit';
		$('body').append('<script src="//maps.googleapis.com/maps/api/js?key='+key+'&signed_in=true&callback='+fName+'" async="" defer=""></script>');
	}

});
$(window).load(function() {
	$(".dot").dotdotdot({watch: "window"});
});

function shopsFormListener() {
	let $input = $('.shops__filter input');
	if ($input.length) {
		let $form = $input.closest('form');
		$input.change( ()=> {
			$form.submit();
		});

		$form[0].onreset = () => {
			$form.submit();
		};
	}
}

function ajaxFormSubmit($formSelector, $resultContainer){
	$resultContainer.addClass('ajax-loader--active');
	let url = $formSelector.attr('action');
	let jqXHR = $.ajax({
		url: url,
		type: "GET",
		data: $formSelector.serialize()
	});
	jqXHR.done(function (data) {
		setTimeout(()=>{
			$resultContainer.removeClass('ajax-loader--active')
			.html( data );
		}, 200);
	});
	jqXHR.fail(function () {
		console.log('error on submiting ajax form');
	});
}

function fileUploader(selector) {
	var inputs = selector;
	Array.prototype.forEach.call(inputs, function (input) {
		var label = input.parentElement,
		result = label.querySelector('span'),
		labelVal = result.innerHTML;

		input.addEventListener('change', function (e) {
			var fileName = '';
			if (this.files && this.files.length > 1) fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);else {
				fileName = e.target.value.split('\\').pop();
			}

			if (fileName) {
				result.innerHTML = fileName;
				result.setAttribute('title', fileName);
			} else {
				result.innerHTML = labelVal;
				result.setAttribute('title', labelVal);
			}
		});
	});
}

function mapInit() {
	var mapElem = document.getElementById('mapit');
	var inlineData = $(mapElem).data();
	var mapOptions = {
		center: {
			lat: inlineData.lat,
			lng: inlineData.lng
		},
		zoom: 16,
		scrollwheel: false,
		zoomControl: true,
		disableDoubleClickZoom: true,
		mapTypeControl: false,
		scaleControl: true,
		panControl: false,
		streetViewControl: false,
		draggable: true,
		overviewMapControl: false
	};
	var map = new google.maps.Map(mapElem, mapOptions);
	var contentString = ''
	+'<div id="content">'
	+'<div>'+inlineData.title+'</div>'
	+'</div>'
	;
	var infowindow = new google.maps.InfoWindow({
		content: contentString
	});
	var marker = new google.maps.Marker({
		position: new google.maps.LatLng(inlineData.lat, inlineData.lng),
		map: map,
		icon: inlineData.marker,
		title: inlineData.title
	});
	marker.addListener('click', function() {
		infowindow.open(map, marker);
	});
}

function getScrollbarSize() {
	let scrollbarSize = undefined;
	if(scrollbarSize === undefined) {
		var scrollDiv = document.createElement("div");
		scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
		document.body.appendChild(scrollDiv);
		scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);
	}
	return scrollbarSize;
}